footer {    
    background-color: #CC2C3E;
    padding-top: 10px;
    color: #FFFFFF;
}


footer .container{
    max-width: 100%;
}

@media screen and (min-width: 768px){

    footer .wrapper{
        border-top: 2px solid #FFFFFF;
    }
}

footer .wrapper {
    width: 100%;
}

.edudeta {
    height:30px;
}