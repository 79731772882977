.searchResult:hover {
    border-bottom: 4px solid #CC2C3E;
    cursor: pointer;
}

.search-bar {
    background-color: rgb(255, 242, 242);
}

.text-black {
    color:black!important;
}

.search.locationInput{
    width:170px!important;
}
