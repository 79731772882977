.label{
	max-width:120px;
}

.buttons{
	width:200px;
	margin:auto;
}

.buttons .btn{
	margin-bottom:5px;
}
.dossier > .form-control{
	margin-bottom:10px;
}
